import '../styles/App.scss';
import '../styles/header.scss'
//import { useState } from 'react';

function Header(props) {

  return (
    
    <div className="header zindex">
      <section className='icon-name'>
      <img className='dev-icon' src="logo.gif" alt='dev-icon'/>
      <div className="home item" onClick={() => props.updatesPage('home')} value='home'>BF</div>
      </section>
      
      <section className="pagelinks">
 

      <div className="about item" onClick={() => props.updatesPage('about')} value='about'>About Me</div>
      <div className="projects item" onClick={() => props.updatesPage('projects')} value='projects'>Projects</div>
      <div className="contact item" onClick={() => props.updatesPage('contact')} value='contact'>Contact Me</div>
      </section>
      <div className="hire item" > <a href="https://resume.creddle.io/resume/2p8fmq2vvlg">Hire Me</a> </div>
    </div>
  );
}

export default Header;