import "../styles/App.scss";
import "../styles/aboutm.scss";
//import Firefly from './Firefly';
//import { useState } from 'react';

function About(props) {
  return (
    <div className="about-m">

      <p className="bio-m">
        <strong className='hilight'>Hi!</strong> My name is <strong className='hilight'>Ben Finlay</strong>. <br /><br />
      I have about one year's experience in full-stack web development.  I have spent most of my time working with <strong className='hilight'>Javascript, Node.js, PostgresSql, React, and SASS.</strong>
      <br></br>
      <br></br>
      I am a <strong className='hilight'>life-long learner</strong>, and am always up for a challenge and a chance to learn new things.
      <br></br>
      <br></br>
      I have always had a thing for tinkering, so when I started learning code it was mostly C, for coding microcontrollers like Arduinos, and PICs.  From there I ended up in <strong className='hilight'>Electrical Engineering</strong>, working with PLCs, motor controllers, and solving hydraulic and pneumatic problems.    Utilizing my academic background in <strong className='hilight'>Philosophy</strong> I found the problem-solving and logical structures of coding very enjoyable.
      <br></br>
      <br></br>
      It seemed a natural development to move into <strong className='hilight'>Web Development</strong>, and focus on coding full time.
      Getting my feet wet I started with Python, before moving into JavaScript and from there, attending <strong className='hilight'>Lighthouse Labs bootcamp</strong>.
      <br></br>
      <br></br>
      My experience in the bootcamp and working on personal projects, has provided me with a solid background in <strong className='hilight'>fullstack development, and testing</strong>.
      <br></br>
      <br></br>
      At home I still enjoy tinkering on small projects, like an automated watering system for my plants, weather stations, or making smart devices for the home.  I also love travelling, seeing new places, trying new food and meeting new people.
      <br></br>
      <br></br>
      <strong className='hilight'>Thanks for stopping by!</strong></p>

      
      <div className="wrapper-m">

        <img className="profile-pic-m" src="face-sq.png" alt="profile pic" />

        <div>
      <div className="hilight txt-m">A Little About Me</div>
      </div>
      </div>

    </div>
  );
}

export default About;
