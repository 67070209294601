import '../styles/App.scss';
import '../styles/contact.scss'
import emailjs from '@emailjs/browser';
import { useRef, useState, useEffect } from 'react';

function Email(props) {

  const form = useRef();
  const [cooldown, setCooldown] = useState(false)

  const sendEmail = (e) => {
    e.preventDefault();
    if (!cooldown) {
      console.log('email sent')
    emailjs.sendForm(
      'service_qphg0ro',
      'template_ih85i5x', 
      form.current,
      '_IvO96xcYtEinzXXd'
      )
      .then((result) => {
          console.log(result.text);
          setCooldown(true)
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()
      
    } 
  };



  return (
    <form ref={form} onSubmit={sendEmail}>
    {cooldown && <div className='sendemail'>Thanks for the Email!</div>}
    
    <label> Your Name </label>
    <input type="text" name="user_name" placeholder='Name' className='username-m' required/>
    <label> Your Email </label>
    <input type="email" name="user_email" placeholder='Email' className='useremail-m' required/>
    <label> Your Message </label>
    <textarea name="message" placeholder='Your Message' className='emailmsg-m' required/>
    <input className='emailbtn' type="submit" value="Send" />
  </form>
  );
}

  export default Email;