import "../styles/App.scss";
import "../styles/projects.scss";
//import { useState } from 'react';

function Projects(props) {
  return (
    <div className="background">
      <div className="rows">
        <div className="page-content">

          <div class="card bg n1">
           
            <div class="contents">
              <h2 class="title">Quotes Homepage</h2>
              <p class="copy">
                React, Node, Express, PostgresSQL
              </p>
              <a href="https://github.com/Ben-Finlay/Quotes-React">
              <button class="btn">Github</button>
              </a>
              <a href="https://quotes-home.onrender.com/">
              <button class="btn">Live Demo</button>
              </a>
            </div>
            
          </div>

          <div class="card bg n2">
            <div class="contents">
              <h2 class="title">Plan Ahead!</h2>
              <p class="copy">React, SCSS, Node, Express, Prisma, PostgresSQL</p>
              <a href="https://github.com/Ben-Finlay/plan-ahead">
              <button class="btn">Github</button>
              </a>
              <a href="https://plan-ahead.fly.dev/">
              <button class="btn">Live Demo</button>
              </a>
            </div>
          </div>
          <div class="card bg n3">
            <div class="contents">
              <h2 class="title">Edutag</h2>
              <p class="copy">
                Node, EJS, Express, SASS
              </p>
              <a href="https://github.com/Ben-Finlay/EduTag">
              <button class="btn">Github</button>
              </a>
            </div>
          </div>

          <div class="card bg n4">
            <div class="contents">
              <h2 class="title">Interview Scheduler</h2>
              <p class="copy">
                React, Webpack, Babel, Axios,<br></br> Storybook, Cypress, Jest
              </p>
              <a href="https://github.com/Ben-Finlay/scheduler">
              <button class="btn">Github</button>
              </a>
            </div>
          </div>
          <div class="card bg n5">
            <div class="contents">
              <h2 class="title">Jungle Plant Store</h2>
              <p class="copy">
                Ruby, Rails, PostgresSQL, Webpacker, RMagick
              </p>
              <a href="https://github.com/Ben-Finlay/Jungle-Rails">
              <button class="btn">Github</button>
              </a>
            </div>
          </div>
          <div class="card bg n6">
            <div class="contents">
              <h2 class="title">More to Come!</h2>
              <p class="copy">
                Check out my Github to see what I'm working on!
              </p>
              <a href="https://github.com/Ben-Finlay/">
              <button class="btn">Github</button>
              </a>
            </div>
          </div>
          </div>



      </div>
    </div>
  );
}

export default Projects;
