import "../styles/App.scss";
import "../styles/about.scss";
//import Firefly from './Firefly';
//import { useState } from 'react';

function About(props) {
  return (
    <div className="about">
      
      <p className="bio"><strong className='hilight'>Hi!</strong> My name is <strong className='hilight'>Ben Finlay</strong>. <br /><br />
      I have about one year's experience in full-stack web development.  I have spent most of my time working with <strong className='hilight'>Javascript, Node.js, PostgresSql, React, and SASS.</strong>
      <br></br>
      <br></br>
      I am a <strong className='hilight'>life-long learner</strong>, and am always up for a challenge and a chance to learn new things.
      <br></br>
      <br></br>
      I have always had a thing for tinkering, so when I started learning code it was mostly C, for coding microcontrollers like Arduinos, and PICs.  From there I ended up in <strong className='hilight'>Electrical Engineering</strong>, working with PLCs, motor controllers, and solving hydraulic and pneumatic problems.    Utilizing my academic background in <strong className='hilight'>Philosophy</strong> I found the problem-solving and logical structures of coding very enjoyable.
      <br></br>
      <br></br>
      It seemed a natural development to move into <strong className='hilight'>Web Development</strong>, and focus on coding full time.
      Getting my feet wet I started with Python, before moving into JavaScript and from there, attending <strong className='hilight'>Lighthouse Labs bootcamp</strong>.
      <br></br>
      <br></br>
      My experience in the bootcamp and working on personal projects, has provided me with a solid background in <strong className='hilight'>fullstack development, and testing</strong>.
      <br></br>
      <br></br>
      At home I still enjoy tinkering on small projects, like an automated watering system for my plants, weather stations, or making smart devices for the home.  I also love travelling, seeing new places, trying new food and meeting new people.
      <br></br>
      <br></br>
      <strong className='hilight'>Thanks for stopping by!</strong></p>
      
      <div className="wrapper">
        <div className='circle'>
        <svg
          version="1.1"
          width="600"
          height="600"
          viewBox="0 0 480 490"
          fill="none"
          stroke="#F4f4f4"
          stroke-linecap="round"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          className="svg-anim"
        >
          <defs>
            <path id="r1">
              <animate
                id="p1"
                attributeName="d"
                values="m160,160l0,0 0,0;m130,110l30,-17 30,17;m130,60l30,-17 30,17;m160,20l0,0 0,0"
                dur="6s"
                repeatCount="indefinite"
              />
              <animate
                attributeName="stroke-width"
                values="0;4;4;4;0"
                dur="6s"
                repeatCount="indefinite"
                begin="p1.begin"
              />
            </path>
            <path id="r2">
              <animate
                attributeName="d"
                values="m160,160l0,0 0,0;m130,110l30,-17 30,17;m130,60l30,-17 30,17;m160,20l0,0 0,0"
                dur="6s"
                repeatCount="indefinite"
                begin="p1.begin+1s"
              />
              <animate
                attributeName="stroke-width"
                values="0;4;4;4;0"
                dur="6s"
                repeatCount="indefinite"
                begin="p1.begin+1s"
              />
            </path>
            <path id="r3">
              <animate
                attributeName="d"
                values="m160,160l0,0 0,0;m130,110l30,-17 30,17;m130,60l30,-17 30,17;m160,20l0,0 0,0"
                dur="6s"
                repeatCount="indefinite"
                begin="p1.begin+2s"
              />
              <animate
                attributeName="stroke-width"
                values="0;4;4;4;0"
                dur="6s"
                repeatCount="indefinite"
                begin="p1.begin+2s"
              />
            </path>
            <path id="r4">
              <animate
                id="p1"
                attributeName="d"
                values="m160,160l0,0 0,0;m130,110l30,-17 30,17;m130,60l30,-17 30,17;m160,20l0,0 0,0"
                dur="6s"
                repeatCount="indefinite"
                begin="p1.begin+3s"
              />
              <animate
                attributeName="stroke-width"
                values="0;4;4;4;0"
                dur="6s"
                repeatCount="indefinite"
                begin="p1.begin+3s"
              />
            </path>
            <path id="r5">
              <animate
                attributeName="d"
                values="m160,160l0,0 0,0;m130,110l30,-17 30,17;m130,60l30,-17 30,17;m160,20l0,0 0,0"
                dur="6s"
                repeatCount="indefinite"
                begin="p1.begin+4s"
              />
              <animate
                attributeName="stroke-width"
                values="0;4;4;4;0"
                dur="6s"
                repeatCount="indefinite"
                begin="p1.begin+4s"
              />
            </path>
            <path id="r6">
              <animate
                attributeName="d"
                values="m160,160l0,0 0,0;m130,110l30,-17 30,17;m130,60l30,-17 30,17;m160,20l0,0 0,0"
                dur="6s"
                repeatCount="indefinite"
                begin="p1.begin+5s"
              />
              <animate
                attributeName="stroke-width"
                values="0;4;4;4;0"
                dur="6s"
                repeatCount="indefinite"
                begin="p1.begin+5s"
              />
            </path>
          </defs>
          <use xlinkHref="#r1" />
          <use xlinkHref="#r1" transform="rotate(60 160 160)" />
          <use xlinkHref="#r1" transform="rotate(120 160 160)" />
          <use xlinkHref="#r1" transform="rotate(180 160 160)" />
          <use xlinkHref="#r1" transform="rotate(240 160 160)" />
          <use xlinkHref="#r1" transform="rotate(300 160 160)" />
          <use xlinkHref="#r2" transform="rotate(30 160 160)" />
          <use xlinkHref="#r2" transform="rotate(90 160 160)" />
          <use xlinkHref="#r2" transform="rotate(150 160 160)" />
          <use xlinkHref="#r2" transform="rotate(210 160 160)" />
          <use xlinkHref="#r2" transform="rotate(270 160 160)" />
          <use xlinkHref="#r2" transform="rotate(330 160 160)" />
          <use xlinkHref="#r3" />
          <use xlinkHref="#r3" transform="rotate(60 160 160)" />
          <use xlinkHref="#r3" transform="rotate(120 160 160)" />
          <use xlinkHref="#r3" transform="rotate(180 160 160)" />
          <use xlinkHref="#r3" transform="rotate(240 160 160)" />
          <use xlinkHref="#r3" transform="rotate(300 160 160)" />
          <use xlinkHref="#r4" transform="rotate(30 160 160)" />
          <use xlinkHref="#r4" transform="rotate(90 160 160)" />
          <use xlinkHref="#r4" transform="rotate(150 160 160)" />
          <use xlinkHref="#r4" transform="rotate(210 160 160)" />
          <use xlinkHref="#r4" transform="rotate(270 160 160)" />
          <use xlinkHref="#r4" transform="rotate(330 160 160)" />
          <use xlinkHref="#r5" />
          <use xlinkHref="#r5" transform="rotate(60 160 160)" />
          <use xlinkHref="#r5" transform="rotate(120 160 160)" />
          <use xlinkHref="#r5" transform="rotate(180 160 160)" />
          <use xlinkHref="#r5" transform="rotate(240 160 160)" />
          <use xlinkHref="#r5" transform="rotate(300 160 160)" />
          <use xlinkHref="#r6" transform="rotate(30 160 160)" />
          <use xlinkHref="#r6" transform="rotate(90 160 160)" />
          <use xlinkHref="#r6" transform="rotate(150 160 160)" />
          <use xlinkHref="#r6" transform="rotate(210 160 160)" />
          <use xlinkHref="#r6" transform="rotate(270 160 160)" />
          <use xlinkHref="#r6" transform="rotate(330 160 160)" />
        </svg>
        <img className="profile-pic" src="face-sq.png" alt="profile pic" />
        </div>
      </div>
    </div>
  );
}

export default About;
