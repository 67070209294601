import "../styles/App.scss";
import "../styles/home.scss";
//import { useState, useEffect } from 'react';

function Home(props) {
  return (
    <div className="zindex">
      <div className="landing-page">
      <div className="content">
        <div className="name-noamin glitch">Ben Finlay
        
          <div className="basic-intro-noamin">
            <p><strong className="hilight">Hello World!</strong></p>

            <p className='tabsmall'>I'm a Full Stack Developer. </p>
            
            <p className='tabbig'>I <img className="heart" src="heart.gif" alt="heart" /> to code and
            create new things.</p>
          </div>
          </div>
          <img className="hello" src="hello.png" alt="hello robot" />
        </div>
      </div>
    </div>
  );
}

export default Home;
