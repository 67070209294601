import "../styles/App.scss";
import "../styles/homem.scss";
//import { useState, useEffect } from 'react';

function Home(props) {
  return (
    <div className="zindex">
      <div className="landing-page-m">
      <div className="content-m">     
          <div className="basic-intro-noamin-m">
            <p><strong className="hilight">Hello World!</strong></p>

            <p>I'm a Full Stack Developer. </p>
            
            <p>I <img className="heart-m" src="heart.gif" alt="heart" /> to code and
            create new things.</p>
          </div>
          <div className='name-noamin-m'> 
          <p>Ben Finlay</p>
          <img className="hello-m" src="hello.png" alt="hello robot" /> 
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
