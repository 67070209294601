//import "../styles/App.scss";
import "../styles/contact.scss";
import Email from "../components/Email";

function Contact(props) {
  return (
    <div className="contactpage">
      <p className='connect'><strong className='hilight'>Connect with me</strong></p>
      <div className="services">
        
        <div className="linkedin">
          <a href="https://www.linkedin.com/in/ben-finlay/">
            {" "}
            <img src="LI.png" alt="LinkedIn"></img>
          </a>
        </div>
        <div className="github">
          <a href="https://www.github.com/ben-finlay">
            {" "}
            <img src="GH.png" alt="Github"></img>
          </a>
        </div>
        <div className="mail">
          <a href="mailto:benjamin.finlay+portfolio@gmail.com">
            {" "}
            <img src="MAIL.png" alt="Email"></img>
          </a>
        </div>
      </div>
      <br></br>
      <div className="email">
        {/* Or send me an email at: <i>'benjamin dot finlay at gmail dot com'</i>:
        <br></br> */}
        <Email />
      </div>
    </div>
  );
}

export default Contact;
