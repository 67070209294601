import './styles/App.scss';
import { useState, useEffect } from 'react';

import Header from './components/Header';
import Home from './components/Home';
import HomeM from './components/HomeM';
import About from './components/About';
import AboutM from './components/AboutM';
import Projects from './components/Projects';
import ProjectsM from './components/ProjectsM';
import Contact from './components/Contact';
import ContactM from './components/ContactM';
import Firefly from './components/Firefly';



function App() {

  const [page, setPage] = useState('home')
  const [mobile, setMobile] = useState('true');

  const handleWindowSizeChange = () => {
    window.innerWidth < 966 ? setMobile('true') : setMobile('false');
  };

  useEffect(() => {
    handleWindowSizeChange()
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    };
}, []);


  return (
    <div className="App">
      {mobile !== 'true' && 
      < Header 
        page={page} 
        updatesPage={(name) => { setPage(name)} }/>
      }

      {mobile === 'true' && 
      <div>
      <HomeM />
      <AboutM />
      <ProjectsM />
      <ContactM />
      </div>
      
      
      }

      <Firefly />
        
      {page === 'home' && mobile !== 'true' &&
        <Home />
      }

      {page === 'about' && mobile !== 'true' &&
        <About />
      }

      {page === 'projects' && mobile !== 'true' &&
        <Projects />
      } 

      {page === 'contact' && mobile !== 'true' &&
        <Contact />
      } 

    </div>
  );
}

export default App;
